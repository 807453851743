import React, { useEffect, useState } from 'react';
import { data } from '../../configs/menu-config';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Input,
  notification,
  Row,
  Typography,
  Radio,
} from 'antd';
import authService from '../../services/auth';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../redux/slices/auth';
import {
  fetchRestSettings,
  fetchSettings,
} from '../../redux/slices/globalSettings';
import { useTranslation } from 'react-i18next';
import Recaptcha from 'components/recaptcha';
import { setMenu } from 'redux/slices/menu';
import ProIdLogo from 'assets/images/pro-id-logo.png';
import Logo from 'assets/images/brand-logo.svg';
const { Title } = Typography;

const credentials = [
  {
    login: 'admin@githubit.com',
    password: 'githubit',
  },
  {
    login: 'manager@githubit.com',
    password: 'manager',
  },
  {
    login: 'sellers@githubit.com',
    password: 'seller',
  },
  {
    login: 'moderator@githubit.com',
    password: 'moderator',
  },
  {
    login: 'delivery@githubit.com',
    password: 'delivery',
  },
  // {
  //   login: 'temp@gmail.com',
  //   password: '12345678',
  // },
];

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [userType, setUserType] = useState('chef');

  const [loading, setLoading] = useState(false);
  const { settings } = useSelector((state) => state.globalSettings);
  const [recaptcha, setRecaptcha] = useState(null);
  const handleRecaptchaChange = (value) => {
    setRecaptcha(value);
  };
  const isDemo = Boolean(Number(settings?.is_demo));

  const handleLogin = (values) => {
    const body = {
      password: values.password,
    };
    if (values.email.includes('@')) {
      body.email = values.email;
    } else {
      body.phone = values.email.replace(/[^0-9]/g, '');
    }
    setLoading(true);
    authService
      .login(body)
      .then((res) => {
        const user = {
          fullName: res.data.user.firstname + ' ' + res.data.user.lastname,
          role: res.data.user.role,
          urls: data[res.data.user.role],
          img: res.data.user.img,
          token: res.data.access_token,
          email: res.data.user.email,
          id: res.data.user.id,
          shop_id: res.data.user?.shop?.id,
        };
        if (user.role === 'waiter') {
          dispatch(
            setMenu({
              icon: 'user',
              id: 'orders-board',
              name: 'my.orders',
              url: 'waiter/orders-board',
            })
          );
        }
        if (user.role === 'user') {
          notification.error({
            message: t('ERROR_101'),
          });
          return;
        }
        localStorage.setItem('token', res.data.access_token);
        dispatch(setUserData(user));
        if (user.role === 'admin') {
          dispatch(fetchSettings());
        } else {
          dispatch(fetchRestSettings());
        }
      })
      .finally(() => setLoading(false));
  };

  const handleChangeUserType = (e) => {
    setUserType(e.target.value);
  };

  const copyCredentials = (event, item) => {
    event.preventDefault();
    form.setFieldsValue({ email: item.login, password: item.password });
  };

  const renderForm = () => {
    if (userType === 'admin') {
      return (
        <>
          <Form.Item
            name='email'
            label='Email'
            rules={[
              {
                required: true,
                message: 'Please input your Email!',
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className='site-form-item-icon' />}
              placeholder='Email or phone'
            />
          </Form.Item>
          <Form.Item
            name='password'
            label={t('Password')}
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className='site-form-item-icon' />}
              placeholder={t('Password')}
            />
          </Form.Item>
          <Recaptcha onChange={handleRecaptchaChange} />
          <Form.Item className='login-input mt-4'>
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button'
              loading={loading}
              disabled={!Boolean(recaptcha)}
            >
              {t('Login')}
            </Button>
          </Form.Item>
        </>
      );
    }

    return (
      <Form.Item>
        <a
          href='https://api.cheeff.uz/api/v1/oauth/proid/web_admin/login'
          className='login-btn'
        >
          <img src={ProIdLogo} alt='Pro Id Logo' width={112} height={36} />
          <span>{t('enter.by.pro.id')}</span>
        </a>
      </Form.Item>
    );
  };

  useEffect(() => {
    dispatch(fetchRestSettings());
  }, []);

  return (
    <div className='login-container'>
      <div className='container d-flex flex-column justify-content-center h-100 align-items-end'>
        <Row justify='center'>
          <Col>
            <Radio.Group
              onChange={handleChangeUserType}
              value={userType}
              style={{ marginBottom: 8 }}
            >
              <Radio.Button value='chef'>For Chefs</Radio.Button>
              <Radio.Button value='admin'>For Admins</Radio.Button>
            </Radio.Group>

            <Card className='card'>
              <div className='my-4 pl-4 pr-4 w-100'>
                <div className='app-brand text-center'>
                  <Title className='brand-logo'>
                    <img src={Logo} alt='Cheeff Logo' width={120} />
                  </Title>
                </div>
                <Row justify='center'>
                  <Col>
                    <Form
                      name='login-form'
                      layout='vertical'
                      form={form}
                      onFinish={handleLogin}
                      style={{ width: '420px' }}
                    >
                      {renderForm()}
                      {isDemo && (
                        <Descriptions bordered size='small'>
                          {credentials.map((item, idx) => (
                            <React.Fragment key={idx}>
                              <Descriptions.Item span={2} label={item.login}>
                                {item.password}
                              </Descriptions.Item>
                              <Descriptions.Item span={1}>
                                <a
                                  href='/'
                                  className='copy-link'
                                  onClick={(event) =>
                                    copyCredentials(event, item)
                                  }
                                >
                                  {t('Copy')}
                                </a>
                              </Descriptions.Item>
                            </React.Fragment>
                          ))}
                        </Descriptions>
                      )}{' '}
                      {/* {!isDemo && process.env.REACT_APP_IS_DEMO === 'true' && (
                        <Descriptions size='small'>
                          <Descriptions.Item span={4} label='Login'>
                            {credentials[0].login}
                          </Descriptions.Item>
                          <Descriptions.Item span={4} label='Password'>
                            {credentials[0].password}
                          </Descriptions.Item>
                        </Descriptions>
                      )} */}
                    </Form>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Login;
