import { allRoles } from 'configs/menu-config';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { batch } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { clearUser } from 'redux/slices/auth';
import { setCurrentChat } from 'redux/slices/chat';
import { clearMenu } from 'redux/slices/menu';
import { useLogin } from 'shared/hooks/useLogin';

export const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { handleLogin } = useLogin();

  const code = searchParams.get("code");
  useEffect(() => {
    if(code) {
      handleLogin(code)
    }
  }, [])

  useEffect(() => {
    if(!user || !allRoles.includes(user?.role)) {
      batch(() => {
        dispatch(clearUser());
        dispatch(clearMenu());
        dispatch(setCurrentChat(null))
        localStorage.removeItem("token");
        navigate("/login")
      })
    }
  }, [user])

  if(code) {
    return null;
  }

  if (!user) {
    return <Navigate to='/login' replace />;
  }
  return (
    children
  );
};
