export const PROJECT_NAME = 'Cheeff';
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'https://api.cheeff.uz';
export const WEBSITE_URL = 'https://cheeff.uz';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = '';
export const MAP_API_KEY = 'AIzaSyC9h3dxmuSoKPBNYDaAwMYIK_FjyMFqRd0';
export const export_url = BASE_URL + '/storage/';
export const example = BASE_URL + '/';

export const VAPID_KEY =
  'BHFv5NaxfCmJ9s2VPGdSG9TZ5gdux45UOpJW9fUDoFeqAsXv8XFZmzMI7vp84B6QAKiCD1eMy8E4M9f1RRPfRR0';

export const LAT = 41.311501;
export const LNG = 69.279434;

export const API_KEY = 'AIzaSyAqzzxjpLYbRLPnyPH1V-x2oShdHO7lyN8';
export const AUTH_DOMAIN = 'cheeff-780e4.firebaseapp.com';
export const PROJECT_ID = 'cheeff-780e4';
export const STORAGE_BUCKET = 'cheeff-780e4.appspot.com';
export const MESSAGING_SENDER_ID = '897397171615';
export const APP_ID = '1:897397171615:web:65d578ae790cf9b16409aa';
export const MEASUREMENT_ID = 'G-KDYRK3SVZC';

export const RECAPTCHASITEKEY = '6Lc573spAAAAAB9PNDZmYdvXxhpZcsEB9fQzIMAc';

export const DEMO_SELLER = 334; // seller_id
export const DEMO_SELLER_UUID = '3566bdf6-3a09-4488-8269-70a19f871bd0'; // seller_id
export const DEMO_SHOP = 599; // seller_id
export const DEMO_DELIVERYMAN = 375; // deliveryman_id
export const DEMO_MANEGER = 114; // deliveryman_id
export const DEMO_MODERATOR = 297; // deliveryman_id
export const DEMO_ADMIN = 107; // deliveryman_id

export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];
